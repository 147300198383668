<template>
  <div v-if="$store.getters.isAdmin" class="create-user-as-admin-page">
    <create-account-form @updateEmail="email = $event" />
  </div>
</template>

<script>
import CreateAccountForm from 'src/components/user/CreateAccountForm';

export default {
  components: {
    CreateAccountForm,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.create-user-as-admin-page {
  max-width: 800px;
  justify-self: center;
}
</style>
